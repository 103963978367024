@import '../../../../../scss/bhStyles/variables.module.scss';

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacing-6;
  margin-top: $spacing-12;

  @media screen and (max-width: $breakpoint-large) {
    grid-template-columns: 1fr;
    margin-top: $spacing-8;
  }

  :global(.bs-svg-icon) {
    fill: $primary-regular;
  }
}
.wrapper {
  min-width: 100%;
  min-height: 10.5rem;
  display: flex;
  flex-direction: column;
}

.toggle {
  margin-top: $spacing-2;
}

.img-wrapper,
.img-wrapper-upload {
  width: 100%;
  height: 100%;
  max-height: 8.75rem;
  display: flex;
  border-radius: $border-radius-medium;
  .icon {
    fill: $primary-regular;
  }
}
.img-wrapper {
  align-items: center;
  justify-content: center;
  border: 1px dashed $semi-dark-gray;
}
.img-wrapper-upload {
  flex-direction: column;
  overflow: hidden;
  max-height: 15.625rem;
  position: relative;

  :global(img) {
    height: calc(100% - $spacing-10);
    object-fit: cover;
  }
}
.img-title {
  display: none;
  @media screen and (max-width: $breakpoint-large) {
    display: initial;
    margin-bottom: $spacing-2;
  }
}

.img-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $spacing-10;
  bottom: 0;
  width: 100%;
  color: $primary-regular;
  background: $primary-xxl;
}

.upload-text {
  color: $primary-regular;
}
.description {
  margin-top: $spacing-2;
  color: $semi-dark-gray;
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-5;
  @media screen and (max-width: $breakpoint-large) {
    row-gap: $spacing-4;
  }
}

.title {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: $spacing-5;
  @media screen and (max-width: $breakpoint-large) {
    grid-column-end: 2;
    font-size: $heading-font-size-20;
  }
}

.toggle-wrapper {
  @media screen and (max-width: $breakpoint-large) {
    margin-bottom: $spacing-4;
  }
}
