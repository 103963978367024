@import '../../../../../../scss/bhStyles/variables.module.scss';

.main {
  display: grid;
  column-gap: $spacing-6;
  row-gap: $spacing-5;
  grid-template-columns: 1fr 1fr;
  margin-top: $spacing-12;

  @media screen and (max-width: $breakpoint-large) {
    grid-template-columns: 1fr;
    margin-top: $spacing-8;
    row-gap: $spacing-4;
  }

  :global(.bs-svg-icon) {
    fill: $primary-regular;
  }
}

.title {
  grid-column-start: 1;
  grid-column-end: 3;

  @media screen and (max-width: $breakpoint-large) {
    grid-column-end: 2;
    font-size: $heading-font-size-20;
  }
}
